import { Typography, styled, useTheme } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";
import {
	Amenities,
	Area,
	Balconies,
	Bathrooms,
	Bedrooms,
	Budget,
	BusinessProfileType,
	Floors,
	Furnishing,
	LandFacing,
	ListedBy,
	PlotDimensions,
	PropertyCategory,
	PropertyStatus,
	PropertyType,
	SecurityDeposit,
	ServiceSubtype,
	ServiceType,
	UnitFacing,
} from ".";
import EndOfLocationResultMobile from "../../public/images/illustrations/end-of-location-search-mobile.svg";
import EndOfLocationResult from "../../public/images/illustrations/end-of-location-search.svg";
import NoSearchResultsMobile from "../../public/images/illustrations/no-results-search-mobile.svg";
import NoSearchResults from "../../public/images/illustrations/no-results-search.svg";
import StateInterface from "../../redux-magic/state-interface";
import { setSearchAnimationDetailsThunk } from "../../redux-magic/store";
import BusinessProfileCardGridView from "../cards/BusinessProfileCards/BusinessProfileCardGridView";
import BusinessProfileCardListView from "../cards/BusinessProfileCards/BusinessProfileCardListView";
import OFBCardGridView from "../cards/OFBCardGridView/OFBCardFetcher";
import OFBCardListView from "../cards/OFBCardListView/OFBCardListView";
import PropertyCardGridView from "../cards/PropertyCardGridMobileThree/PropertyCardFetcher";
import PropertyCardListView from "../cards/PropertyCardListView/PropertyCardListView";
import ServiceCardGridView from "../cards/ServiceCardGridView/ServiceCardFetcher";
import ServiceCardListView from "../cards/ServiceCardListView/ServiceCardListView";
import BeegruButton from "../common-components/buttons/BeegruButton";
import SearchAfterAnimationComplete from "../page-sections/Index/SearchAfterAnimationComponent";
import SearchMobile from "../page-sections/Index/SearchMobileComponent/SearchMobile";
import AppliedFilters from "./AppliedFilters";
const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	paddingTop: "4rem",
	[theme.breakpoints.down("md1190")]: {
		display: "flex",
		justifyContent: "flex-start",
		width: "100%",
	},
}));

const SnackbarContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	[theme.breakpoints.down("md1220")]: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-start",
		width: "100%",
		gap: "0.5rem",
	},
}));

const DesktopFilterDiv = styled("div")(({ theme }) => ({
	display: "flex",
	position: "sticky",
	flexDirection: "column",
	width: "25%",
	gap: "1rem",
	[theme.breakpoints.down("md1190")]: {
		display: "none",
	},
}));

const DesktopFilterDivSomething = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
	rowGap: "0.75rem",
	flexWrap: "wrap",
	/*642*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		gap: "0.75rem",
	},
	/*1107*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		gap: "1rem",
		rowGap: "1rem",
	},
	[theme.breakpoints.up("md1190")]: {
		width: "75%",
	},
}));

const LoadMoreDiv = styled("div")(({ theme }) => ({
	width: "100%",
	height: "10rem",
	pointerEvents: "none",
	cursor: "none",
}));

const BannerDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
}));

const FilterComponents = ({
	search_filter_state,
	dispatch,
	searchanimationsettings,
	search_filters_metrices,
	session,
	profile_context,
	PropertyIds,
	search_api_response,
	CollectionName,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
	session: any;
	profile_context: any;
	PropertyIds: any;
	search_api_response: StateInterface["SeacrhApiResponse"];
	CollectionName: any;
}) => {
	const theme = useTheme();
	const { ref, inView } = useInView({ threshold: 0 });
	const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const [blockLoadMore, setBlockLoadMore] = React.useState(false);

	React.useEffect(() => {
		/*

		* when in view lets change the searchanimationsettings.trigger to false

			&& blockLoadMore is used to block the load more button when the search filters are changed

		*/
		if (inView && blockLoadMore) {
			// setIsLoadingLoadmorediv(true);
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: true,
					PropertySubCategory: searchanimationsettings.PropertySubCategory,
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView, blockLoadMore]);

	React.useEffect(() => {
		/*

		* Checking if search filter state is changed then block the inview from triggering the api. it will give us new search filters data. so block it. simple

		*/
		if (search_filter_state) {
			setBlockLoadMore(true);
		}
	}, [search_filter_state]);

	let displayPropertyStatus = true;

	switch (search_filter_state.property_type.selected_options[0]?.id) {
		case "land_residential":
		case "land_commercial":
		case "land_agricultural":
		case "land_industrial":
		case "mining":
			displayPropertyStatus = false;
	}

	/*

	* Checking if the distance is above 5km or not. if it is then we will store it in isDistanceAboveThreshold to true.

	*/

	const EndOfLocationComponent = () => {
		let location = "";
		if (
			typeof search_filter_state?.location.selected_options[0]?.value === "object" &&
			"title" in search_filter_state?.location.selected_options[0]?.value
		) {
			location = search_filter_state?.location.selected_options[0]?.value.title;
		}
		return windowWidth > 590 ? (
			<BannerDiv
				sx={{
					gap: "1.5rem",
				}}
			>
				<EndOfLocationResult
					width={135}
					height={135}
					viewBox={"0 0 135 135"}
				/>
				<Typography
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						fontSize: "1.25rem",
						fontWeight: 500,
						color: theme.palette.text.primary,
						lineHeight: "2rem",
						letterSpacing: "0.00938rem",
					}}
				>
					<span>
						Oops! That&apos;s all the {CollectionName} we have in {location} right now. But you can
					</span>
					<span> keep scrolling to see {CollectionName} in other locations.</span>
				</Typography>
			</BannerDiv>
		) : (
			<BannerDiv
				sx={{
					gap: "1rem",
				}}
			>
				<EndOfLocationResultMobile
					width={80}
					height={80}
					viewBox={"0 0 80 80"}
				/>
				<Typography
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						fontSize: "0.875rem",
						fontWeight: 500,
						color: theme.palette.text.primary,
						lineHeight: "1.1rem",
						letterSpacing: "0.00938rem",
						[theme.breakpoints.down("xs")]: { fontSize: "0.6875rem" },
					}}
				>
					<span>Oops! That&apos;s all the {CollectionName} we have in </span>
					<span>{location} right now. But you can keep</span>
					<span>scrolling to see {CollectionName}in other </span>
					<span>locations.</span>
				</Typography>
			</BannerDiv>
		);
	};

	/*

	* Getting the index of banner_location from PropertyIds

	*/
	const bannerLocationIndex = PropertyIds.indexOf("banner_location");

	const ComponentToRender = PropertyIds.map((propertyId: any, key: any) => {
		const isBannerLocation = propertyId === "banner_location";

		/*

		* Lets check if the propertyId banner_location exists or not.
		* bannerLocationIndex === -1 this is to check if the banner_location is not present in the PropertyIds. if not its a relavant property
		* if it is then lets set the DivId to relevant_property or unrelevant_property

		*/

		const DivId = bannerLocationIndex === -1 || key < bannerLocationIndex ? "relevant_property" : "unrelevant_property";

		const renderCard = () => {
			switch (CollectionName) {
				case "ofb":
					return (
						<OFBCardListView
							id={propertyId}
							dispatch={dispatch}
							session={session}
							profile_context={profile_context}
							key={key}
						/>
					);
				case "properties":
					return (
						<PropertyCardListView
							PropertyId={propertyId}
							id={DivId}
							dispatch={dispatch}
							session={session}
							profile_context={profile_context}
							key={key}
						/>
					);
				case "business_profiles":
					return (
						<BusinessProfileCardListView
							_id={propertyId}
							key={key}
						/>
					);
				case "services":
					return (
						<ServiceCardListView
							ServiceId={propertyId}
							dispatch={dispatch}
							session={session}
							key={key}
						/>
					);
			}
		};

		const renderMobileCard = () => {
			switch (CollectionName) {
				case "ofb":
					return (
						<OFBCardGridView
							id={propertyId}
							dispatch={dispatch}
							session={session}
							profile_context={profile_context}
							source="search"
							key={key}
						/>
					);
				case "properties":
					return (
						<PropertyCardGridView
							PropertyId={propertyId}
							id={DivId}
							dispatch={dispatch}
							session={session}
							profile_context={profile_context}
							source="search"
							key={key}
						/>
					);
				case "business_profiles":
					return (
						<BusinessProfileCardGridView
							_id={propertyId}
							source="search"
							key={key}
						/>
					);
				case "services":
					return (
						<ServiceCardGridView
							ServiceId={propertyId}
							dispatch={dispatch}
							session={session}
							profile_context={profile_context}
							source="search"
							key={key}
						/>
					);
			}
		};

		return windowWidth >= 2160 ? (
			!isBannerLocation ? (
				renderCard()
			) : (
				<EndOfLocationComponent />
			)
		) : !isBannerLocation ? (
			renderMobileCard()
		) : (
			<EndOfLocationComponent />
		);
	});
	let type =
		search_filter_state.search_type.selected_options[0].id === "properties"
			? "Properties"
			: search_filter_state.search_type.selected_options[0].id === "services"
				? "Services"
				: "Business Users";

	const [showAdvancedFilters, setShowAdvancedFilters] = React.useState(false);

	let BudgetTrigger = search_filter_state.business_profile_type.is_applied ? true : false;

	return (
		<>
			<SnackbarContainer
				sx={{
					"@keyframes CustomFadeOut": {
						"0%": { opacity: 0 },
						"25%": { opacity: 0 },
						"50%": { opacity: 0 },
						"75%": { opacity: 0.5 },
						"100%": { opacity: 1 },
					},
					animation: searchanimationsettings.StartAnimate ? "CustomFadeOut 0.5s linear 1" : "none",
				}}
			>
				<SearchAfterAnimationComplete
					dispatch={dispatch}
					search_filter_state={search_filter_state}
					searchanimationsettings={searchanimationsettings}
				/>
				<SearchMobile
					search_filter_state={search_filter_state}
					dispatch={dispatch}
					searchanimationsettings={searchanimationsettings}
					search_filters_metrices={search_filters_metrices}
				/>
			</SnackbarContainer>

			<CustomDiv
				sx={{
					"@keyframes CustomFadeOut": {
						"0%": { opacity: 0 },
						"25%": { opacity: 0 },
						"50%": { opacity: 0 },
						"75%": { opacity: 0.5 },
						"100%": { opacity: 1 },
					},
					animation: searchanimationsettings.StartAnimate ? "CustomFadeOut 0.5s linear 1" : "none",
					padding: "0.5rem 0rem 0.5rem 0rem",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						width: "100%",
						gap: "1rem",
						paddingTop: "1rem",
					}}
				>
					<DesktopFilterDiv>
						<AppliedFilters
							search_filter_state={search_filter_state}
							dispatch={dispatch}
							searchanimationsettings={searchanimationsettings}
							search_type={search_filter_state.search_type.selected_options[0].id as string}
						/>

						{search_filter_state.service_type.is_visible && !search_filter_state.property_type.is_visible && (
							<ServiceType
								search_id="service_type"
								service_category_value={search_filter_state.service_category?.selected_options[0]?.value as string}
								dispatch={dispatch}
								service_type={search_filter_state.service_type}
							/>
						)}
						{search_filter_state.service_subtype.is_visible && !search_filter_state.property_type.is_visible && (
							<ServiceSubtype
								search_id="service_subtype"
								service_type_value={search_filter_state.service_type?.selected_options[0]?.id as string}
								dispatch={dispatch}
								service_subtype={search_filter_state.service_subtype}
							/>
						)}
						{search_filter_state.property_category.is_visible && !search_filter_state.service_type.is_visible && (
							<PropertyCategory
								search_id="property_category"
								property_category={search_filter_state.property_category}
								dispatch={dispatch}
							/>
						)}
						{search_filter_state.property_type.is_visible &&
							!search_filter_state.service_type.is_visible &&
							!["public_infrastructure", "joint_development", "joint_venture"].includes(
								search_filter_state.property_category?.selected_options[0]?.id,
							) && (
								<PropertyType
									search_id="property_type"
									property_category_value={search_filter_state.property_category?.selected_options[0]?.value as string}
									dispatch={dispatch}
									property_type={search_filter_state.property_type}
								/>
							)}
						{!BudgetTrigger && search_filter_state.budget.is_visible && (
							<Budget
								search_id="budget"
								dispatch={dispatch}
								budget={search_filter_state.budget}
							/>
						)}
						{search_filter_state.bedrooms.is_visible && search_filter_state.property_type.is_visible && (
							<Bedrooms
								search_id="bedrooms"
								dispatch={dispatch}
								bedrooms={search_filter_state.bedrooms}
							/>
						)}
						{showAdvancedFilters &&
							search_filter_state.area.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Area
									search_id="area"
									dispatch={dispatch}
									area={search_filter_state.area}
								/>
							)}
						{showAdvancedFilters &&
							search_filter_state.property_status.is_visible &&
							displayPropertyStatus &&
							search_filter_state.property_type.is_visible && (
								<PropertyStatus
									search_id="property_status"
									dispatch={dispatch}
									property_status={search_filter_state.property_status}
									property_type={search_filter_state.property_type}
									transaction_type={search_filter_state.transaction_type}
								/>
							)}
						{search_filter_state.unit_facing.is_visible && search_filter_state.property_type.is_visible && (
							<UnitFacing
								search_id="unit_facing"
								dispatch={dispatch}
								unit_facing={search_filter_state.unit_facing}
							/>
						)}
						{showAdvancedFilters &&
							search_filter_state.land_facing.is_visible &&
							search_filter_state.property_type.is_visible && (
								<LandFacing
									search_id="land_facing"
									dispatch={dispatch}
									land_facing={search_filter_state.land_facing}
								/>
							)}
						{showAdvancedFilters &&
							search_filter_state.furnishing.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Furnishing
									search_id="furnishing"
									dispatch={dispatch}
									furnishing={search_filter_state.furnishing}
								/>
							)}
						{showAdvancedFilters && search_filter_state.security_deposit.is_visible && (
							<SecurityDeposit
								search_id="security_deposit"
								dispatch={dispatch}
								security_deposit={search_filter_state.security_deposit}
							/>
						)}
						{showAdvancedFilters &&
							search_filter_state.amenities.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Amenities
									search_id="amenities"
									dispatch={dispatch}
									amenities={search_filter_state.amenities}
								/>
							)}

						{showAdvancedFilters &&
							search_filter_state.balconies.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Balconies
									search_id="balconies"
									dispatch={dispatch}
									balconies={search_filter_state.balconies}
								/>
							)}
						{showAdvancedFilters &&
							search_filter_state.bathrooms.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Bathrooms
									search_id="bathrooms"
									dispatch={dispatch}
									bathrooms={search_filter_state.bathrooms}
								/>
							)}
						{search_filter_state.plot_dimensions.is_visible && search_filter_state.property_type.is_visible && (
							<PlotDimensions
								search_id="plot_dimensions"
								dispatch={dispatch}
								plot_dimensions={search_filter_state.plot_dimensions}
							/>
						)}

						{/* {search_filter_state.possession_date.is_visible && search_filter_state.property_type.is_visible && (
							<PossessionDate
								search_id="possession_date"
								dispatch={dispatch}
								possession_date={search_filter_state.possession_date}
							/>
						)} */}
						{/* {search_filter_state.available_from.is_visible && search_filter_state.property_type.is_visible && (
							<AvailableFrom
								search_id="available_from"
								dispatch={dispatch}
								available_from={search_filter_state.available_from}
							/>
						)} */}

						{showAdvancedFilters &&
							search_filter_state.floors.is_visible &&
							search_filter_state.property_type.is_visible && (
								<Floors
									search_id="floors"
									dispatch={dispatch}
									floors={search_filter_state.floors}
								/>
							)}

						{search_filter_state.business_profile_type.is_visible &&
							search_filter_state.business_profile_type.is_applied && (
								<BusinessProfileType
									search_id="business_profile_type"
									dispatch={dispatch}
									business_profile_type={search_filter_state.business_profile_type}
								/>
							)}
						{!BudgetTrigger && showAdvancedFilters && search_filter_state.listed_by.is_visible && (
							<ListedBy
								search_id="listed_by"
								listed_by={search_filter_state.listed_by}
								dispatch={dispatch}
							/>
						)}
						{search_filter_state.business_profile_type.is_applied ? null : (
							<BeegruButton
								flavor="primary"
								variant="contained"
								size="large"
								sx={{
									dsiaplay: "flex",
									marginTop: "1rem",
									[theme.breakpoints.down("md")]: {
										width: "100%",
									},
								}}
								onClick={() => {
									setShowAdvancedFilters(!showAdvancedFilters);
								}}
								// endIcon={showAdvancedFilters ? <VisibilityIcon /> : <VisibilityOffIcon />}
							>
								{showAdvancedFilters ? "Hide" : "Show"} Advanced filters
							</BeegruButton>
						)}
						{/* <ServiceCategory
							search_id="service_category"
							dispatch={dispatch}
							service_category={search_filter_state.service_category}
						/> */}
					</DesktopFilterDiv>
					<DesktopFilterDivSomething>
						{search_api_response?.total_no_of_results === undefined ? (
							"Please try changing filters"
						) : search_api_response?.total_no_of_results !== 0 ? null : (
							<BannerDiv>
								{windowWidth > 590 ? (
									<NoSearchResults
										width={211}
										height={218}
										viewBox={"0 0 211 218"}
									/>
								) : (
									<NoSearchResultsMobile
										width={84}
										height={87}
										viewBox={"0 0 84 87"}
									/>
								)}
								{windowWidth > 590 ? (
									<Typography
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											fontSize: "1.25rem",
											fontWeight: 500,
											color: theme.palette.text.primary,
											lineHeight: "2rem",
											letterSpacing: "0.00938rem",
										}}
									>
										<span>Couldn&apos;t find any matching search {CollectionName}.</span>
										<span>Try tweaking your search and check out other awesome options!</span>
									</Typography>
								) : (
									<Typography
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											fontSize: "0.875rem",
											fontWeight: 400,
											color: theme.palette.text.primary,
											lineHeight: "1.14125rem",
											letterSpacing: "0.025rem",
											[theme.breakpoints.down("xs")]: { fontSize: "0.75rem" },
										}}
									>
										<span>Couldn&apos;t find any matching search {CollectionName}.</span>
										<span>Try tweaking your search and check out</span>
										<span>other awesome options!</span>
									</Typography>
								)}
							</BannerDiv>
						)}
						{ComponentToRender}
						{PropertyIds && PropertyIds.length < 4 ? null : <LoadMoreDiv ref={ref} />}
					</DesktopFilterDivSomething>
				</div>
			</CustomDiv>
		</>
	);
};

export default FilterComponents;
