/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { SvgIcon, Typography, styled } from "@mui/material";

/* Icons / Illustrations */

import Sale from "../../../public/images/icons/home/sale.svg";
import Rent from "../../../public/images/icons/home/rent.svg";
import Resale from "../../../public/images/icons/home/resale.svg";
import DeveloperProjects from "../../../public/images/icons/home/developer_projects.svg";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../redux-magic/thunks";
import { useRouter } from "next/router";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "1.25rem 0rem 1.25rem 0rem",
	// [theme.breakpoints.up("sm")]: {
	// 	display: "none",
	// },
}));

const CardsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "space-between",
	flexWrap: "wrap",
	width: "100%",
	gap: "0.5rem",
}));

const CategoryCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "0.75rem 0.75rem 0.75rem 0.75rem",
	width: "48%",
	gap: "0rem",
	background: theme.palette.background.paper,
	borderRadius: "16px",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
}));

const TextColumn = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	gap: "0rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	padding: "0rem 0rem 0rem 0.25rem",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	lineHeight: "1rem",
	color: theme.palette.text.secondary,
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 600,
	color: theme.palette.text.primary,
}));

const CategorySection = ({
	search_filter_state,
	searchanimationsettings,
	dispatch,
}: {
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	dispatch: Function;
}) => {
	const router = useRouter();

	const category_cards = [
		{
			subtext: "Properties for",
			title: "Sale",
			illustration: Sale,
		},
		{
			subtext: "Properties for",
			title: "Rent",
			illustration: Rent,
		},
		{
			subtext: "Properties for",
			title: "Resale",
			illustration: Resale,
		},
		{
			subtext: "New developer",
			title: "Projects",
			illustration: DeveloperProjects,
		},
	];

	const handleCardClick = (category: string) => {
		switch (category) {
			case "Sale":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

					* Setting search Type to properties and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "properties",
										title: "Properties",
										value: "properties",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

					* Setting Transaction Type to buy and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [
									{
										id: "buy",
										title: "Buy",
										value: "buy",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_category",
								isVisible: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_type",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;
			case "Rent":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

					* Setting search Type to properties and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "properties",
										title: "Properties",
										value: "properties",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

					* Setting Transaction Type to buy and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [
									{
										id: "rent",
										title: "Rent",
										value: "rent",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_category",
								isVisible: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_type",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;

			case "Resale":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

					* Setting search Type to properties and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "properties",
										title: "Properties",
										value: "properties",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

					* Setting Transaction Type to buy and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [
									{
										id: "buy",
										title: "Buy",
										value: "buy",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_category",
								isVisible: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_type",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;

			case "Projects":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

					* Setting search Type to properties and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "properties",
										title: "Properties",
										value: "properties",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

					* Setting Transaction Type to buy and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [
									{
										id: "buy",
										title: "Buy",
										value: "buy",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_category",
								isVisible: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_type",
								isVisible: true,
							}),
						),
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "listed_by",
								newOptions: [
									{
										id: "developer",
										title: "Developer",
										value: "developer",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "listed_by",
								isApplied: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;

			default:
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

					* Setting search Type to properties and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "properties",
										title: "Properties",
										value: "properties",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

					* Setting Transaction Type to buy and is applied to true

					*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "transaction_type",
								newOptions: [
									{
										id: "buy",
										title: "Buy",
										value: "buy",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "transaction_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_category",
								isVisible: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "property_type",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;
		}
	};

	return (
		<MainContainer>
			<Heading variant="h6">What are you looking for?</Heading>

			<CardsContainer>
				{category_cards.map((category, index) => {
					return (
						<CategoryCard
							key={index}
							onClick={() => {
								handleCardClick(category.title);
							}}
						>
							<TextColumn>
								<SubText variant="subtitle1">{category.subtext}</SubText>
								<Title variant="subtitle1">{category.title}</Title>
							</TextColumn>

							<SvgIcon
								component={category.illustration}
								inheritViewBox={false}
								viewBox="0 0 40 40"
								sx={{
									width: "50px",
									height: "30px",
								}}
							/>
						</CategoryCard>
					);
				})}
			</CardsContainer>
		</MainContainer>
	);
};

export default CategorySection;
