/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { SvgIcon, Typography, styled, useTheme } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

/* Icons / Illustrations */

import ExplorePossibilities from "../../../public/images/icons/home/banners/get_into_real_estate.svg";
import { setCreateNewPropertyCurrentPageThunk } from "../../../redux-magic/store";
import CreatePropertyWithUploadTool from "../../modals/PropertyModal/CreatePropertyWithUploadTool";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "0rem 0rem 0rem 0rem",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const CarouselCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "100%",
}));

const BannerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "100%",
	gap: "0.5rem",
	margin: "0rem 0rem 2rem 0rem",
	borderRadius: "16px",
	background: "linear-gradient(90deg, rgba(252, 128, 25, 0.19) 0%, rgba(252, 128, 25, 0.00) 100%)",
}));

const CarouselComponent = styled(Carousel)(({ theme }) => ({
	width: "100%",
	height: "100%",
	position: "relative",
	gap: "0rem",
	"& .carousel .control-dots": {
		position: "absolute",
		bottom: "0.25rem", // Move dots below the banner
		left: "50%",
		transform: "translateX(-50%)",
		margin: "0rem 0rem 0rem 0rem",
	},
	"& .carousel .control-dots .dot": {
		backgroundColor: "#d9d9d9",
		opacity: 1,
		margin: "0rem 0.25rem 0rem 0.25rem",
		boxShadow: "none",
		height: "0.5rem",
		width: "0.5rem",
	},
	"& .carousel .control-dots .dot.selected": {
		backgroundColor: "#a7a7a7",
		height: "0.65rem",
		width: "0.65rem",
	},
	"& .carousel .slide": {
		textAlign: "left",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
	padding: "1rem 1rem 1rem 1rem",
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const SubTtext = styled(Typography)(({ theme }) => ({
	fontSize: "0.675rem",
	lineHeight: "0.95rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
}));

const MiniBanner = ({
	session,
	dispatch,
	search_filter_state,
	searchanimationsettings,
	new_listing_url,
	newPropertyData,
	isStudio,
}: {
	session: any;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	new_listing_url: StateInterface["new_listing_url"];
	newPropertyData: StateInterface["new_property_data"];
	isStudio: boolean;
}) => {
	const theme = useTheme();

	const [autoplay, setAutoplay] = React.useState(true);

	const [create, setCreate] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("business_profile"));
		setCreate(true);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	const banners = [
		{
			title: "Real estate marketplace",
			subtitle: "Discover the smarter way to navigate real estate with us!",
			icon: ExplorePossibilities,
		},
		{
			title: "Post your property",
			subtitle: "Get started by posting your property on our platform!",
			icon: ExplorePossibilities,
		},
		{
			title: "Post your service",
			subtitle: "Get started by posting your service on our platform!",
			icon: ExplorePossibilities,
		},
		{
			title: "Search for properties",
			subtitle: "Get started and find the perfect property for you!",
			icon: ExplorePossibilities,
		},
	];

	return (
		<MainContainer>
			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
				studio={isStudio}
			/>

			<CarouselComponent
				autoPlay={autoplay}
				showArrows={false}
				showThumbs={false}
				swipeable={true}
				showStatus={false}
				infiniteLoop={true}
				interval={3000}
				showIndicators={true}
				stopOnHover={true}
				onSwipeStart={() => setAutoplay(false)}
				onSwipeEnd={() => setAutoplay(true)}
			>
				{banners.map((banner, index) => (
					<CarouselCard key={index}>
						<BannerContainer>
							<TextContainer>
								<Title variant="h6">{banner.title}</Title>
								<SubTtext>{banner.subtitle}</SubTtext>
							</TextContainer>

							<SvgIcon
								component={banner.icon}
								inheritViewBox={false}
								viewBox="0 0 141 71"
								sx={{
									width: "8.5rem",
									height: "4rem",
								}}
							/>
						</BannerContainer>
					</CarouselCard>
				))}
			</CarouselComponent>
		</MainContainer>
	);
};

export default MiniBanner;
