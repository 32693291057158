/* Common Imports */

import React from "react";

/* Component Imports */

import { styled } from "@mui/material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	height: "25vh",
	padding: "0rem 0rem 0rem 0rem",
	margin: "4rem 0rem 0rem 0rem",
	backgroundSize: "contain",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom",
	backgroundImage:
		theme.palette.mode === "dark"
			? "url(images/illustrations/index/homepage_banner.svg)"
			: "url(images/illustrations/index/homepage_banner.svg)",
	[theme.breakpoints.down(500)]: {
		height: "21vh",
	},
	[theme.breakpoints.down(430)]: {
		height: "18vh",
	},
	[theme.breakpoints.down(360)]: {
		height: "14vh",
	},
}));

const CoverBannerSection = ({}: {}) => {
	return <MainContainer />;
};

export default CoverBannerSection;
