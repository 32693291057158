/*

? First, let's import the essentials - React, Layout, styled and Theme.

*/

import React from "react";
import { styled } from "@mui/system";

/* Redux Importss */

import { ObjectId } from "mongodb";
import { useRouter } from "next/router";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import Link from "next/link";
import { Chip, IconButton, useTheme } from "@mui/material";
import OFBListCardImage from "./sub-component/OFBListCardImage";
import OFBListCardContact from "./sub-component/OFBListCardContact";
import OFBListCardLocation from "./sub-component/OFBListCardLocation";
import OFBListCardTitle from "./sub-component/OFBListCardTitle";
import OFBListLoadingCard from "../skeletonLoadingCard/OFBListLoadingCard";
import { BookmarkBorder, BookmarkOutlined } from "@mui/icons-material";
import OFBListCardType from "./sub-component/OFBListCardType";
import { addSaveEntityBusinessProfileThunk } from "../../../redux-magic/store";

/*

& Next, let's style all the components we intend to use on this page.

*/

const OFBLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	height: "auto",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const MainContainer = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: "100%",
	height: "13rem",
	padding: "1.25rem",
	gap: "1.5rem",
	[theme.breakpoints.down("sm")]: { display: "none" },
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "42rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "45rem",
	},
	[theme.breakpoints.up("sm822")]: {
		width: "47rem",
	},
	[theme.breakpoints.up("sm910")]: {
		width: "51rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "55.5rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "54rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "64rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "55rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "65.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "71rem",
	},
}));

const OFBCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	height: "100%",
	width: "65%",
	overflow: "hidden",
}));

const TitleAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	margin: "0rem 0rem 2rem 0rem",
}));

const IconsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.5rem",
}));

const CustomChip = styled(Chip)(({ theme }) => ({
	height: "1.75rem",
	// fontWeight: 500,
	fontSize: "0.875rem",
	letterSpacing: "0.02857em",
	padding: "0rem 0.5rem",
	borderRadius: "8px",
	backgroundColor: "#ffc5c5",
	color: "#212121",
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
	padding: "0.25rem",
	width: "2rem",
	height: "2rem",
	borderRadius: "12px",
	background: theme.palette.mode === "dark" ? "#FC801933" : "#FC801929",
	"&:hover": {
		background: "#E6DACF",
	},
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "1rem",
	width: "100%",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const OFBCardListView = ({
	id,
	dispatch,
	session,
	profile_context,
}: {
	id: ObjectId | string;
	dispatch: Function;
	session: any;
	profile_context: any;
}) => {
	const theme = useTheme();

	const [ofbData, setOfbData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (id !== undefined) {
			fetch(`${process.env.PRODUCTION_API_URL}properties/cards/${id}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setOfbData(data.property_data);
					setLoading(false);
				});
		}
	}, [id]);

	const [bookmark, setBookmark] = React.useState(
		ofbData.saved_content?.find((item: any) => {
			return profile_context && profile_context.is_business_profile
				? item.saved_by.business_profile_id ===
						(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
				: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
		}) !== undefined,
	);

	React.useEffect(() => {
		setBookmark(
			ofbData.saved_content?.find((item: any) => {
				return profile_context && profile_context.is_business_profile
					? item.saved_by.business_profile_id ===
							(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
					: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
			}) !== undefined,
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile_context, bookmark]);

	const router = useRouter();

	const pathname = React.useRef(router).current;

	const submit = async () => {
		if (session === null) {
			window.open("/auth/login");
		} else {
			await dispatch(
				addSaveEntityBusinessProfileThunk({
					is_business_profile: profile_context?.is_business_profile,
					user_id: profile_context?.user_id,
					business_profile_id: profile_context?.business_profile_id,
					entity_id: ofbData._id,
					entity_type: "ofb",
					add_remove: !bookmark ? "add" : "remove",
					url: pathname.asPath ? pathname.asPath : "",
				}),
			);
			setBookmark(!bookmark);
		}
	};

	let BoostingCheck = ofbData?.boost_information?.active ? true : false;

	if (loading) {
		return <OFBListLoadingCard />;
	} else {
		return (
			<OFBLink
				href={`/properties/${ofbData.slug}`}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
				title={ofbData.title}
			>
				<MainContainer
					sx={{
						border: BoostingCheck ? "3px solid #fdb375" : "none",
						background: BoostingCheck ? "#fc80190d" : theme.palette.background.paper,
					}}
				>
					{/* Image */}

					<OFBListCardImage
						loading={ofbData.loading}
						imagesrc={ofbData.images}
						alt="OFB"
						transaction_type={ofbData.transaction_type}
					/>

					<OFBCardInfoContainer>
						<TitleAndTransactionContainer>
							{/* Title */}

							<OFBListCardTitle
								loading={loading}
								title={ofbData.title !== "" ? ofbData.title : "Unknown Title"}
							/>

							<IconsContainer onClick={(e) => e.preventDefault()}>
								{/* Transaction Type Chip */}

								<CustomChip label="OFB" />

								{/* Bookmark Icon */}

								<BookmarkIconButton
									aria-label="bookmark"
									onClick={(e) => {
										submit();
									}}
								>
									{!bookmark ? (
										<BookmarkBorder
											fontSize="small"
											style={{ color: theme.palette.mode === "dark" ? "#FFFFFF" : "#623816" }}
										/>
									) : (
										<BookmarkOutlined
											fontSize="small"
											style={{ color: theme.palette.mode === "dark" ? "#FFFFFF" : "#623816" }}
										/>
									)}
								</BookmarkIconButton>
							</IconsContainer>
						</TitleAndTransactionContainer>

						<CardRow>
							{/* OFB Type */}

							<OFBListCardType
								loading={ofbData.loading}
								propertyType={
									ofbData.property_type
										? ofbData.property_type.length > 0
											? ofbData.property_type
											: [ofbData.property_category]
										: []
								}
							/>

							<VerticalLine />

							{/* Location */}

							<OFBListCardLocation
								loading={ofbData.loading}
								location={
									ofbData.location
										? ofbData.location.title !== ""
											? ofbData.location.title
											: "Unknown Location"
										: "Unknown Location"
								}
							/>
						</CardRow>

						{/* CTA */}

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								alignItems: "center",
								width: "100%",
								padding: "0rem 0rem 0rem 0rem",
							}}
						>
							<OFBListCardContact
								loading={ofbData.loading}
								dispatch={dispatch}
								session={session}
								slug={ofbData.slug}
								title={ofbData.title}
								created_by={ofbData.created_by}
								show_contact_details={ofbData.show_contact_details}
							/>
						</div>
					</OFBCardInfoContainer>
				</MainContainer>
			</OFBLink>
		);
	}
};

export default OFBCardListView;
