/*

? First, let's import React and Theme.

*/

import loadable from "@loadable/component";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useInView } from "react-intersection-observer";
import Fabs from "../components/page-sections/Fabs/Fabs";
import FilterComponents from "../components/search/FilterComponents";
/*

? Next, let's import Layout from the layout-components folder.

*/
import Layout from "../components/layout-components/Layout";
/*

? Next, let's import redux realted components.

	*/
import { connect } from "react-redux";
import StateInterface from "../redux-magic/state-interface";
import {
	fetchHomePageHotPropertiesThunk,
	fetchHomePageTopBusinessProfilesThunk,
	fetchHomePageTopServicesThunk,
	// setHomePageBusinessProfileLoadingThunk,
	fetchOnBoardingBusinessTitleThunk,
	fetchSearchThunk,
	setHomeLoadingThunk,
	// fetchHomePageHotPropertiesOfbLocalStorageThunk,
	// setSearchFilterMetricesThunk,
	setSearchAnimationDetailsThunk,
	updateReduxSessionThunk,
	updateSearchFilterSelectedOptionsThunk,
	// fetchHomePageTrendingBusinessProfilesThunk,
	wrapper,
} from "../redux-magic/store";
import { is_studio } from "../redux-magic/sub-state-data/is-studio";
import {
	notifications_page,
	on_boarding_details,
	on_boarding_title,
	search_animation_settings,
} from "../redux-magic/sub-state-data/sub-state-data";

/*

? Next, let's import the components that we intend to use on this page i.e(SectionFive, SectionSix, SectionThree, SectionSeven, CardCarousel, HomeHeader)

*/
// const CardCarousel = loadable(() => import("../components/page-sections/Index/CardCarousel"));
import CardCarousel from "../components/page-sections/Index/CardCarousel";
import HomeHeader from "../components/page-sections/Index/HomeHeader";

// const HomeHeader = loadable(() => import("../components/page-sections/Index/HomeHeader"));
// const CardCarousel = loadable(() => import("../components/page-sections/Index/CardCarousel"));

const FirstBannerSection = loadable(() => import("../components/page-sections/Index/FirstBannerSection"));
const SecondBannerSection = loadable(() => import("../components/page-sections/Index/SecondBannerSection"));
const ThirdBannerSection = loadable(() => import("../components/page-sections/Index/ThirdBannerSection"));

import { homePage as home_page } from "../redux-magic/sub-state-data/sub-state-data";
// import { property } from "../redux-magic/sub-state-data/sub-state-data";
/* Library Functions */

import { validateClientSession } from "../lib/auth/helpers";
import authenticationTokenGenerator from "../lib/authentication_token_generator";
// import Search from "../components/page-sections/Index/SearchComponent/Search";
import { useRouter } from "next/router";
import buildDatabaseQueries from "../lib/data/search-filters/helpers/buildDatabaseQueries";
import { updateSearchFilterAppliedThunk } from "../redux-magic/thunks";
import CategorySection from "../components/page-sections/Index/CategorySection";
import { useMediaQuery } from "@mui/material";
import MiniBanner from "../components/page-sections/Index/MiniBanner";
import ServicesSection from "../components/page-sections/Index/ServicesSection";
import CoverBannerSection from "../components/page-sections/Index/CoverBannerSection";

export const getServerSideProps = wrapper.getServerSideProps((store: any) => async (context: any) => {
	const server_session: any = await authenticationTokenGenerator(context);

	return {
		props: {
			server_session: server_session,
		},
	};
});

const Home = ({
	server_session,
	session,
	redux_session,
	homePage,
	lookingFor,
	is_searching,
	dispatch,
	new_listing_url,
	theme,
	showInactivityDialog,
	profile_context,
	new_business_profile_url,
	user_location,
	homeLoading,
	homeLocation,
	notification_data,
	newNotification,
	transaction_type,
	onBoardingDetails,
	onBoardingTitle,
	search_filter_state,
	searchanimationsettings,
	search_filters_metrices,
	search_api_response,
	newPropertyData,
	isStudio,
	newServiceData,
}: {
	server_session: any;
	session: any;
	redux_session: StateInterface["redux_session"];
	homePage: StateInterface["homePage"];
	lookingFor: StateInterface["looking_for"];
	is_searching: StateInterface["is_searching"];
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	theme: Theme;
	showInactivityDialog: boolean;
	profile_context: StateInterface["profile_context"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	user_location: StateInterface["user_location"];
	homeLoading: StateInterface["home_loading"];
	homeLocation: StateInterface["home_location"];
	notification_data: StateInterface["notifications_page"];
	newNotification?: StateInterface["notification"] | null;
	transaction_type: StateInterface["transaction"];
	onBoardingDetails: StateInterface["on_boarding_details"];
	onBoardingTitle: StateInterface["on_boarding_title"];
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	search_filters_metrices: StateInterface["search_filter_metrices"];
	search_api_response: StateInterface["SeacrhApiResponse"];
	newPropertyData: StateInterface["new_property_data"];
	isStudio: StateInterface["is_studio"];
	newServiceData: StateInterface["new_service_data"];
}) => {
	const [initialLoad, setInitialLoad] = React.useState(true);
	const router = useRouter();

	console.log("search filter state", search_filter_state);

	React.useEffect(() => {
		const handlePopState = (event: PopStateEvent) => {
			event.preventDefault();
			window.history.pushState({ urlPath: "/" }, "", "/");
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					ResetSearch: false,
					HomeAccordion: false,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
				}),
			);
		};

		window.addEventListener("popstate", handlePopState);

		return () => {
			window.removeEventListener("popstate", handlePopState);
		};
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, []);

	React.useEffect(() => {
		if (initialLoad && router.asPath === "/") {
			dispatch(
				setHomeLoadingThunk({
					// loading_featured_properties: true,
					loading_hot_properties: true,
					loading_top_business_profiles: true,
					// loading_trending_business_profiles: trendingbusiness,
					loading_top_users: true,
					// loading_trending_users: true,
					loading_top_services: true,
					// loading_trending_services: true,
				}),
			);
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					ResetSearch: false,
					HomeAccordion: false,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
				}),
			);
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));
			setInitialLoad(false);
		}
		if (initialLoad && router.asPath === "/search") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: true,
					TriggerSearch: true,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
					ResetSearch: false,
					HomeAccordion: false,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "location",
					newOptions: [
						{
							id: "location",
							title: "Location",
							value: {
								title: "HSR Layout",
								coordinates: [12.9121, 77.6446],
							},
						},
					],
				}),
			),
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "location",
						isApplied: true,
					}),
				),
				setInitialLoad(false);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [initialLoad]);

	/* Setting redux_session */

	const [reduxSessionUpdated, setReduxSessionUpdated] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (server_session && validateClientSession(server_session)) {
			dispatch(updateReduxSessionThunk(server_session));
		}
		setReduxSessionUpdated(true);
	}, [server_session, dispatch]);

	/*

		* Fetching Hot Properties for different transaction types

	*/

	React.useEffect(() => {
		if (initialLoad) {
			/*

			*uncomment this part for featured properties

			*/
			// dispatch(
			// 	fetchHomePageFeaturedPropertiesThunk({
			// 		sessionId: redux_session ? (redux_session.session_id ? redux_session.session_id : "") : "",
			// 		looking_for: lookingFor,
			// 		location: homeLocation,
			// 		transaction_type: transaction_type,
			// 	}),
			// );
			dispatch(
				fetchHomePageHotPropertiesThunk({
					sessionId: redux_session ? (redux_session.session_id ? redux_session.session_id : "") : "",
					looking_for: lookingFor,
					location: homeLocation,
					transaction_type: transaction_type,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialLoad]);

	const [topbusiness, setTopbusiness] = React.useState(true);

	React.useEffect(() => {
		if (redux_session) {
			const onboardingFetch = async () => {
				await dispatch(fetchOnBoardingBusinessTitleThunk());
			};

			if (redux_session.user.dbUser.onboarding_complete === false) {
				onboardingFetch();
			}
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [dispatch, redux_session]);

	const { ref: hotproperties_ref, inView: hotproperties_inView } = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	// const { ref: featuredproperties_ref, inView: featuredproperties_inView } = useInView({
	// 	threshold: 0,
	// 	triggerOnce: true,
	// });
	// const {
	//   ref: trendingbusinessprofiles_ref,
	//   inView: trendingbusinessprofiles_inView,
	// } = useInView({
	//   threshold: 0,
	//   triggerOnce: true,
	// });
	const { ref: topbusinessprofiles_ref, inView: topbusinessprofiles_inView } = useInView({
		threshold: 0,
		triggerOnce: true,
	});
	const { ref: topservices_ref, inView: topservices_inView } = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	// const { ref: trendingservices_ref, inView: trendingservices_inView } =
	//   useInView({
	//     threshold: 0,
	//     triggerOnce: true,
	//   });
	const { ref: topusers_ref, inView: topusers_inView } = useInView({
		threshold: 0,
		triggerOnce: true,
	});
	// const { ref: trendingusers_ref, inView: trendingusers_inView } = useInView({
	//   threshold: 0,
	//   triggerOnce: true,
	// });

	/*
	 * Setting the home page state variable to the homePage prop that we received from the redux store
	 */

	const [home__page, setHome__Page] = React.useState(home_page);
	React.useEffect(() => {
		setHome__Page(homePage);
	}, [homePage, homeLocation, lookingFor]);

	/*

	* setting default property data that is in sub state data

	*/

	// const [PropertyData, setPropertyData] = React.useState<any>();

	// React.useEffect(() => {
	// 	if (Property) {
	// 		setPropertyData(Property);
	// 	}
	// }, [Property]);

	// console.log(PropertyData, "PropertyData");

	/*

  & Next, let's define the state variables that we intend to use on this page i.e(noContain, data, arrangement)

  ^ In no contain, we will remove the usage of the layout component to match the UI. 0th and 9th index of the array will not use the layout component.

  ^ In data, we will store the data that we intend to use on this page.

  ^ In arrangement, we will store the arrangement of the data that we intend to display on the home page.

  */

	const [noContain, setNoContain] = React.useState<Array<number>>([0]);
	const [data, setData] = React.useState<any>([]);

	const [arrangement, setArrangement] = React.useState([
		// "featured_properties",
		"hot_properties",
		"top_realtors",
		// "trending_business_profiles",
		"top_users",
		// "trending_users",
		"top_services",
		// "trending_services",
	]);

	const [refValue, setRefValue] = React.useState([
		// featuredproperties_ref,
		hotproperties_ref,
		topbusinessprofiles_ref,
		// trendingbusinessprofiles_ref,
		topusers_ref,
		// trendingusers_ref,
		topservices_ref,
		// trendingservices_ref,
	]);

	React.useEffect(() => {
		switch (lookingFor?.looking_for.title) {
			case "Properties":
				setArrangement([
					// "featured_properties",
					"hot_properties",
					"top_realtors",
					// "trending_business_profiles",
					"top_users",
					// "trending_users",
					"top_services",
					// "trending_services",
				]);
				setData([
					// home__page.featured_properties,
					home__page.hot_properties,
					home__page.top_business_profiles,
					// home__page.trending_business_profiles,
					home__page.top_users,
					// home__page.trending_users,
					home__page.top_services,
					// home__page.trending_services,
				]);
				setRefValue([
					// featuredproperties_ref,
					hotproperties_ref,
					topbusinessprofiles_ref,
					// trendingbusinessprofiles_ref,
					topusers_ref,
					// trendingusers_ref,
					topservices_ref,
					// trendingservices_ref,
				]);
				break;
			default:
				setArrangement([
					// "featured_properties",
					"hot_properties",
					"top_realtors",
					// "trending_business_profiles",
					"top_users",
					// "trending_users",
					"top_services",
					// "trending_services",
				]);
				setData([
					home__page.hot_properties,
					// home__page.featured_properties,
					home__page.top_business_profiles,
					// home__page.trending_business_profiles,
					home__page.top_users,
					// home__page.trending_users,
					home__page.top_services,
					// home__page.trending_services,
				]);
				setRefValue([
					hotproperties_ref,
					// featuredproperties_ref,
					topbusinessprofiles_ref,
					// trendingbusinessprofiles_ref,
					topusers_ref,
					// trendingusers_ref,
					topservices_ref,
					// trendingservices_ref,
				]);
				break;
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [
		lookingFor,
		homeLocation,
		home__page.hot_properties,
		// home__page.featured_properties,
		home__page.top_services,
		// home__page.trending_services,
		home__page.top_business_profiles,
		// home__page.trending_business_profiles,
		home__page.top_users,
		// home__page.trending_users,
	]);

	/*

  * Hot Properties and Trending Properties

  */

	React.useEffect(() => {
		if (hotproperties_inView && homeLoading.loading_hot_properties === true && !initialLoad) {
			dispatch(
				fetchHomePageHotPropertiesThunk({
					sessionId: redux_session ? (redux_session.session_id ? redux_session.session_id : "") : "",
					looking_for: lookingFor,
					location: homeLocation,
					transaction_type: transaction_type,
				}),
			);
		} /* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [hotproperties_inView, initialLoad, router]);

	/*

  * Fetch Top and Trending services

  */
	React.useEffect(() => {
		if (topservices_inView) {
			// console.log("is this triggering before the in view");
			dispatch(
				fetchHomePageTopServicesThunk({
					sessionId: redux_session ? (redux_session.session_id ? redux_session.session_id.toString() : "") : "",
					looking_for: lookingFor,
					location: homeLocation,
				}),
			);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [topservices_inView, router]);

	/*

  * Fetch Business Profiles Top and Trending

  */
	React.useEffect(() => {
		if (topbusinessprofiles_inView) {
			dispatch(
				fetchHomePageTopBusinessProfilesThunk({
					sessionId: redux_session ? (redux_session.session_id ? redux_session.session_id.toString() : "") : "",
					looking_for: lookingFor,
					location: homeLocation,
				}),
			);
			setTopbusiness(false);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [topbusinessprofiles_inView, router]);

	/*

	* Triggering Search Animation only on first click and search Api on every trigger search state true

	*/
	const [page_number, setPageNumber] = React.useState(1);
	const [CollectionName, setCollectionName] = React.useState<string>("");
	const [PropertyIds, setPropertyIds] = React.useState<any[]>([]);

	const search_submit = () => {
		const gg = buildDatabaseQueries(search_filter_state);

		if (gg.collection !== null) {
			setCollectionName(
				search_filter_state?.transaction_type.selected_options[0]?.value === "ofb" ? "ofb" : gg.collection,
			);
		}

		dispatch(
			fetchSearchThunk({
				db_queries: gg,
				page_number: page_number,
				page_size: process.env.SEARCH_RESULTS_DEFAULT_PAGE_SIZE,
				search_filter_metrices: search_filters_metrices,
				session_id: redux_session ? (redux_session.session_id ? redux_session.session_id.toString() : "") : "",
				user_id: redux_session ? (redux_session.user ? redux_session.user.dbUser._id : "") : "",
				profile_context: redux_session ? profile_context : null,
			}),
		);
		/*

		* Setting the search animation details to false after the search is triggered.

		*/
		dispatch(
			setSearchAnimationDetailsThunk({
				StartAnimate: searchanimationsettings.StartAnimate,
				TriggerSearch: false,
				PropertySubCategory: searchanimationsettings.PropertySubCategory,
				ResetSearch: searchanimationsettings.ResetSearch,
				HomeAccordion: searchanimationsettings.HomeAccordion,
			}),
		);

		/*

		* Setting the page number to the next page number after the search is triggered.

		*/
		setPageNumber(page_number + 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	/*

	* if Apply filters is clicked, we will trigger the search api and set the page number to 1 and also we need to clear the property ids array to store the new property ids.

	*/
	const [AppliedFiltersPage_number, setAppliedFiltersPageNumber] = React.useState(1);

	/*

	* searchanimationsettings?.ResetSearch === false then we will add the new property ids to the existing property ids array.

	*/
	const [isDistanceAboveThreshold, setIsDistanceAboveThreshold] = React.useState(false);

	const [IndextoBeStored, setIndextoBeStored] = React.useState(0);

	React.useEffect(() => {
		if (search_api_response && searchanimationsettings?.ResetSearch === false) {
			/*

	* using a let to store the distance data. if the distance is above 5km then we will store the distance data in this let.

	*/
			let distance = null;

			/*

	* if isDistanceAboveThreshold is false only then we will store the distance data in the let distance. if true we dont wanna do anything coz its only a 1 time check. this way we can use this as a dependency in the useEffect below. it wont trigger if it changes to true.

	*/
			if (isDistanceAboveThreshold === false && search_api_response.non_boosted_distance.length !== 0) {
				/*

				* Combining distances from non_boosted and boosted into one array

				*/
				distance = [...search_api_response.non_boosted_distance, ...search_api_response.boosted_distance];
				/*

				* Checking if any distance is above the threshold. using some() if any one of the distance is above 5000m or 5km we return true and break the loop.

				*/

				distance.some((d, index) => {
					if (d > parseInt(process.env.NEW_SEARCH_DISTANCE_LIMIT || "5000")) {
						/*

						* Calculating the exact index where the kill threshold is and storing banner_location in that exact position.

						*/
						setIndextoBeStored(PropertyIds.length - distance.length);
						setIsDistanceAboveThreshold(true);
						return true;
					}
					return false;
				});
			}

			const updatedPropertyIds = [...PropertyIds, ...search_api_response.non_boosted, ...search_api_response.boosted];

			/*

			* using splice to store banner_location. we dont have to remove anything so the 0.

			*/
			if (isDistanceAboveThreshold && !updatedPropertyIds.includes("banner_location") && PropertyIds.length !== 0) {
				updatedPropertyIds.splice(IndextoBeStored, 0, "banner_location");
			}
			/*

			* using a set to remove duplicates from the array.

			*/

			setPropertyIds(Array.from(new Set(updatedPropertyIds)));
		} else if (PropertyIds.length === 0 && searchanimationsettings?.ResetSearch === true) {
			const updatedPropertyIds = [...search_api_response.non_boosted, ...search_api_response.boosted];

			/*

	* using a let to store the distance data. if the distance is above 5km then we will store the distance data in this let.

	*/
			let distance = null;

			/*

	* if isDistanceAboveThreshold is false only then we will store the distance data in the let distance. if true we dont wanna do anything coz its only a 1 time check. this way we can use this as a dependency in the useEffect below. it wont trigger if it changes to true.

	*/
			if (isDistanceAboveThreshold === false && search_api_response.non_boosted_distance.length !== 0) {
				/*

				* Combining distances from non_boosted and boosted into one array

				*/
				distance = [...search_api_response.non_boosted_distance, ...search_api_response.boosted_distance];
				/*

				* Checking if any distance is above the threshold. using some() if any one of the distance is above 5000m or 5km we return true and break the loop.

				*/

				distance.some((d, index) => {
					if (d > parseInt(process.env.NEW_SEARCH_DISTANCE_LIMIT || "5000")) {
						/*

						* Calculating the exact index where the kill threshold is and storing banner_location in that exact position.

						*/
						setIndextoBeStored(PropertyIds.length - distance.length);
						setIsDistanceAboveThreshold(true);
						return true;
					}
					return false;
				});
			}

			if (isDistanceAboveThreshold && !updatedPropertyIds.includes("banner_location")) {
				updatedPropertyIds.splice(IndextoBeStored, 0, "banner_location");
			}
			/*

			* using a set to remove duplicates from the array.

			*/
			setPropertyIds(Array.from(new Set(updatedPropertyIds)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_api_response, isDistanceAboveThreshold]);
	/*

	* will trigger only if ResetSearch is true and the property ids array is empty. that is if thwy click on apply filters.

	*/

	React.useEffect(() => {
		if (searchanimationsettings?.ResetSearch === true && searchanimationsettings?.TriggerSearch === true) {
			const apply_filters_submit = () => {
				/*

		* Setting the page number to the next page number after the search is triggered.

		*/

				setPageNumber(page_number);
				setPropertyIds([]);

				const gg = buildDatabaseQueries(search_filter_state);

				if (gg.collection !== null) {
					setCollectionName(
						search_filter_state?.transaction_type.selected_options[0]?.value === "ofb" ? "ofb" : gg.collection,
					);
				}
				dispatch(
					fetchSearchThunk({
						db_queries: gg,
						page_number: AppliedFiltersPage_number,
						page_size: process.env.SEARCH_RESULTS_DEFAULT_PAGE_SIZE,
						search_filter_metrices: search_filters_metrices,
						session_id: redux_session ? (redux_session.session_id ? redux_session.session_id.toString() : "") : "",
						user_id: redux_session ? (redux_session.user ? redux_session.user.dbUser._id : "") : "",
						profile_context: redux_session ? profile_context : null,
					}),
				);

				/*

		* Setting the search animation details to false after the search is triggered.

		*/
				dispatch(
					setSearchAnimationDetailsThunk({
						StartAnimate: searchanimationsettings?.StartAnimate,
						TriggerSearch: false,
						PropertySubCategory: searchanimationsettings.PropertySubCategory,
						ResetSearch: search_animation_settings.ResetSearch,
						HomeAccordion: searchanimationsettings.HomeAccordion,
					}),
				);

				setPageNumber(2);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			};
			apply_filters_submit();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchanimationsettings?.ResetSearch]);

	React.useEffect(() => {
		if (searchanimationsettings?.TriggerSearch === true && searchanimationsettings?.ResetSearch === false) {
			search_submit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchanimationsettings?.TriggerSearch]);

	const functionRender = () => {
		if (searchanimationsettings?.StartAnimate && router.asPath === "/search") {
			return (
				<div
					style={{
						paddingTop: "5rem",
					}}
				>
					<FilterComponents
						session={redux_session}
						search_filter_state={search_filter_state}
						dispatch={dispatch}
						profile_context={profile_context}
						searchanimationsettings={searchanimationsettings ? searchanimationsettings : search_animation_settings}
						search_filters_metrices={search_filters_metrices}
						PropertyIds={PropertyIds}
						search_api_response={search_api_response}
						CollectionName={CollectionName}
					/>
				</div>
			);
		} else if (
			!searchanimationsettings?.StartAnimate &&
			(router.asPath === "/" ||
				router.asPath === "/?create_property=true" ||
				router.asPath === "/?create_service=true" ||
				router.asPath === "/?create_ofb=true")
		) {
			return (
				<div
					style={{
						[theme.breakpoints.down("sm")]: {
							display: "none",
						},
					}}
				>
					<CardCarousel
						index={1}
						data={data[0]}
						loading={homeLoading.loading_hot_properties as boolean}
						type={arrangement[0]}
						profile_context={profile_context}
						dispatch={dispatch}
						session={redux_session}
						innerRef={refValue[0]}
						searchanimationsettings={searchanimationsettings}
					/>

					<FirstBannerSection
						session={redux_session}
						profile_context={profile_context}
						dispatch={dispatch}
						new_listing_url={new_listing_url ? new_listing_url : ""}
						newPropertyData={newPropertyData}
						isStudio={isStudio}
						newServiceData={newServiceData}
					/>

					<CardCarousel
						index={2}
						data={data[1]}
						loading={homeLoading.loading_top_business_profiles as boolean}
						type={arrangement[1]}
						profile_context={profile_context}
						dispatch={dispatch}
						session={redux_session}
						innerRef={refValue[1]}
						searchanimationsettings={searchanimationsettings}
					/>

					<SecondBannerSection
						session={redux_session}
						profile_context={profile_context}
					/>

					<CardCarousel
						index={4}
						data={data[3]}
						loading={homeLoading.loading_top_services as boolean}
						type={arrangement[3]}
						profile_context={profile_context}
						dispatch={dispatch}
						session={redux_session}
						innerRef={refValue[3]}
						searchanimationsettings={searchanimationsettings}
					/>

					<ThirdBannerSection
						session={redux_session}
						profile_context={profile_context}
						dispatch={dispatch}
						new_listing_url={new_listing_url ? new_listing_url : ""}
						newPropertyData={newPropertyData}
						isStudio={isStudio}
						newServiceData={newServiceData}
					/>
				</div>
			);
		} else {
			return (
				<div
					style={{
						height: "100vh",
					}}
				/>
			);
		}
	};

	return (
		<React.Fragment>
			<Layout
				noContain={noContain}
				session={redux_session}
				redux_session_updated={reduxSessionUpdated}
				showInactivityDialog={showInactivityDialog}
				theme={theme}
				dispatch={dispatch}
				profile_context={profile_context}
				new_business_profile_url={new_business_profile_url ? new_business_profile_url : ""}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				notifications={notification_data ? notification_data : notifications_page}
				new_notification={newNotification ? newNotification : null}
				newPropertyData={newPropertyData}
				studio={typeof isStudio === "undefined" ? is_studio : isStudio}
				newServiceData={newServiceData}
			>
				{(router.asPath === "/" ||
					router.asPath === "/?create_property=true" ||
					router.asPath === "/?create_service=true" ||
					router.asPath === "/?create_ofb=true") &&
				!searchanimationsettings?.StartAnimate ? (
					<HomeHeader
						index={0}
						session={redux_session}
						dispatch={dispatch}
						onBoardingDetails={onBoardingDetails ? onBoardingDetails : on_boarding_details}
						onBoardingTitle={onBoardingTitle ? onBoardingTitle : on_boarding_title}
						new_business_profile_url={new_business_profile_url ? new_business_profile_url : ""}
						search_filter_state={search_filter_state}
						searchanimationsettings={searchanimationsettings ? searchanimationsettings : search_animation_settings}
						search_filters_metrices={search_filters_metrices}
					/>
				) : null}

				<div
					style={{
						opacity: searchanimationsettings?.HomeAccordion ? 0.25 : 1,
						color: searchanimationsettings?.HomeAccordion ? "rgba(0, 0, 0, 0.5)" : "transparent",
						pointerEvents: searchanimationsettings?.HomeAccordion ? "none" : "auto",
					}}
				>
					{useMediaQuery(theme.breakpoints.down("sm")) && router.asPath === "/" ? (
						<div
							style={{
								margin: "0rem 0rem 2rem 0rem",
							}}
						>
							{/* Mobile Cover Banner Section */}

							<CoverBannerSection />

							{/* Mobile Category Section */}

							<CategorySection
								search_filter_state={search_filter_state}
								searchanimationsettings={searchanimationsettings ? searchanimationsettings : search_animation_settings}
								dispatch={dispatch}
							/>

							{/* Mobile Banner Carousel Section*/}

							<MiniBanner
								session={redux_session}
								dispatch={dispatch}
								search_filter_state={search_filter_state}
								searchanimationsettings={searchanimationsettings}
								new_listing_url={new_listing_url ? new_listing_url : ""}
								newPropertyData={newPropertyData}
								isStudio={isStudio}
							/>

							{/* Mobile Services Section */}

							<ServicesSection
								dispatch={dispatch}
								search_filter_state={search_filter_state}
								searchanimationsettings={searchanimationsettings}
							/>

							{/* Mobile Card Carousel Section */}

							<CardCarousel
								index={2}
								data={data[1]}
								loading={homeLoading.loading_top_business_profiles as boolean}
								type={arrangement[1]}
								profile_context={profile_context}
								dispatch={dispatch}
								session={redux_session}
								innerRef={refValue[1]}
								searchanimationsettings={searchanimationsettings}
							/>
						</div>
					) : (
						/* Desktop */

						functionRender()
					)}
				</div>

				<Fabs
					session={redux_session}
					dispatch={dispatch}
				/>
			</Layout>
		</React.Fragment>
	);
};

export default connect(
	({
		globalState: {
			homePage,
			looking_for,
			is_searching,
			profile_context,
			new_listing_url,
			new_business_profile_url,
			user_location,
			home_loading,
			home_location,
			notifications_page,
			transaction,
			on_boarding_details,
			on_boarding_title,
			redux_session,
			search_filters_state,
			search_animation_settings,
			search_filter_metrices,
			SeacrhApiResponse,
			new_property_data,
			is_studio,
			new_service_data,
		},
	}: {
		globalState: {
			homePage: StateInterface["homePage"];
			looking_for: StateInterface["looking_for"];
			is_searching: StateInterface["is_searching"];
			profile_context: StateInterface["profile_context"];
			new_listing_url: StateInterface["new_listing_url"];
			new_business_profile_url: StateInterface["new_business_profile_url"];
			user_location: StateInterface["user_location"];
			home_loading: StateInterface["home_loading"];
			home_location: StateInterface["home_location"];
			notifications_page: StateInterface["notifications_page"];
			transaction: StateInterface["transaction"];
			on_boarding_details: StateInterface["on_boarding_details"];
			on_boarding_title: StateInterface["on_boarding_title"];
			redux_session: StateInterface["redux_session"];
			search_filters_state: StateInterface["search_filters_state"];
			search_animation_settings: StateInterface["search_animation_settings"];
			search_filter_metrices: StateInterface["search_filter_metrices"];
			SeacrhApiResponse: StateInterface["SeacrhApiResponse"];
			new_property_data: StateInterface["new_property_data"];
			is_studio: StateInterface["is_studio"];
			new_service_data: StateInterface["new_service_data"];
		};
	}) => ({
		homePage: homePage,
		lookingFor: looking_for,
		is_searching: is_searching,
		profile_context: profile_context,
		new_listing_url: new_listing_url,
		new_business_profile_url: new_business_profile_url,
		user_location: user_location,
		homeLoading: home_loading,
		homeLocation: home_location,
		notification_data: notifications_page,
		transaction_type: transaction,
		onBoardingDetails: on_boarding_details,
		onBoardingTitle: on_boarding_title,
		redux_session: redux_session,
		search_filter_state: search_filters_state,
		searchanimationsettings: search_animation_settings,
		search_filters_metrices: search_filter_metrices,
		search_api_response: SeacrhApiResponse,
		newPropertyData: new_property_data,
		isStudio: is_studio,
		newServiceData: new_service_data,
	}),
)(Home);
