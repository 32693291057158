/* Common imports */

import React from "react";
/* mui imports */
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, useMediaQuery, useTheme } from "@mui/material";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOption } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";

/* redux imports */

/* stylings */

const ServiceSubTypeComponent = ({
	search_id,
	search_filter_state,
	dispatch,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["service_subtype"];
	dispatch: Function;
}) => {
	const theme = useTheme();
	const handleClickServiceRadioType = (value: Array<SearchFilterOption>) => {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: value,
			}),
		);
		/*

		* change is_selected to true for the selected option

		*/
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: search_filter_state.active_options.map((item) => ({
					...item,
					is_selected: item.id === value[0]?.id ? true : false,
				})),
			}),
		);
	};
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	React.useEffect(() => {
		if (isDesktop) {
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: search_filter_state.selected_options.length != 0 ? true : false,
				}),
			);
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: search_id, isVisible: true }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, search_id, dispatch]);
	return (
		<FormControl>
			<FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
			<RadioGroup
				row
				aria-labelledby="demo-row-radio-buttons-group-label"
				name="row-radio-buttons-group"
				sx={{ gap: "1rem", fontSize: "1rem", fontWeight: 400 }}
			>
				{search_filter_state.active_options.slice(0, 10).map((data) => (
					<FormControlLabel
						key={data.id}
						value={data.value}
						control={
							<Radio
								size="small"
								checked={search_filter_state?.selected_options[0]?.id === data.id}
							/>
						}
						label={data.title}
						onChange={() => {
							search_filter_state?.selected_options[0]?.id === data.id
								? handleClickServiceRadioType([] as Array<SearchFilterOption>)
								: handleClickServiceRadioType([data] as Array<SearchFilterOption>);
						}}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
export default ServiceSubTypeComponent;
