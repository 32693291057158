/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { SvgIcon, Typography, styled } from "@mui/material";

/* Icons / Illustrations */

import InteriorDesign from "../../../public/images/icons/services/interior_design.svg";
import Painting from "../../../public/images/icons/services/painting.svg";
import Plumbing from "../../../public/images/icons/services/plumbing.svg";
import Electrician from "../../../public/images/icons/services/electrician.svg";
import PestControl from "../../../public/images/icons/services/pest_control.svg";
import { useRouter } from "next/router";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../redux-magic/thunks";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	[theme.breakpoints.up("sm")]: {
		display: "none",
	},
}));

const CardsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	overflowX: "auto",
	overflowY: "hidden",
	whiteSpace: "nowrap",
	scrollbarWidth: "none", // Hide scrollbar for Firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide scrollbar for Chrome, Safari, and Opera
	},
}));

const CategoryCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	padding: "0.75rem 0.5rem 0rem 0.5rem",
	minWidth: "5.25rem",
	maxWidth: "5.25rem",
	height: "5.25rem",
	gap: "0rem",
	background: theme.palette.background.paper,
	borderRadius: "12px",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 500,
	color: theme.palette.text.primary,
	padding: "0rem 0rem 0rem 0.25rem",
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.675rem",
	lineHeight: "0.75rem",
	fontWeight: 500,
	color: theme.palette.text.secondary,
	textAlign: "center",
	wordBreak: "break-word", // Break words if necessary
	whiteSpace: "normal", // Allow normal wrapping of text
}));

const ServicesSection = ({
	dispatch,
	search_filter_state,
	searchanimationsettings,
}: {
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const router = useRouter();

	const service_categories = [
		{
			title: "Interiors",
			icon: InteriorDesign,
		},
		{
			title: "Painting",
			icon: Painting,
		},
		{
			title: "Plumbing",
			icon: Plumbing,
		},
		{
			title: "Electrician",
			icon: Electrician,
		},
		{
			title: "Pest Control",
			icon: PestControl,
		},
	];

	const handleCardClick = (category: string) => {
		switch (category) {
			case "Interiors":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

						* Setting search Type to services and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "services",
										title: "Services",
										value: "services",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Category and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_category",
								newOptions: [
									{
										id: "regular_service",
										title: "Regular Service",
										value: "regular_service",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_category",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Type and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_type",
								newOptions: [
									{
										id: "interiors_and_decor",
										title: "Interiors and Decor",
										value: "interiors_and_decor",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_type",
								isVisible: true,
							}),
						),
						/*

						* Setting Service Subtype and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_subtype",
								newOptions: [
									{
										id: "interior_design",
										title: "Interior Design",
										value: "interior_design",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_subtype",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_subtype",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;
			case "Painting":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

						* Setting search Type to services and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "services",
										title: "Services",
										value: "services",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Category and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_category",
								newOptions: [
									{
										id: "regular_service",
										title: "Regular Service",
										value: "regular_service",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_category",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Type and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_type",
								newOptions: [
									{
										id: "interiors_and_decor",
										title: "Interiors and Decor",
										value: "interiors_and_decor",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_type",
								isVisible: true,
							}),
						),
						/*

						* Setting Service Subtype and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_subtype",
								newOptions: [
									{
										id: "interior_painting",
										title: "Interior Painting",
										value: "interior_painting",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_subtype",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_subtype",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;

			case "Plumbing":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

						* Setting search Type to services and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "services",
										title: "Services",
										value: "services",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Category and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_category",
								newOptions: [
									{
										id: "regular_service",
										title: "Regular Service",
										value: "regular_service",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_category",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Type and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_type",
								newOptions: [
									{
										id: "home_and_appliance_repair",
										title: "Home and Appliance Repair",
										value: "home_and_appliance_repair",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_type",
								isVisible: true,
							}),
						),
						/*

						* Setting Service Subtype and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_subtype",
								newOptions: [
									{
										id: "plumbing_repair",
										title: "Plumbing Repair",
										value: "plumbing_repair",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_subtype",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_subtype",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;

			case "Electrician":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

						* Setting search Type to services and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "services",
										title: "Services",
										value: "services",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Category and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_category",
								newOptions: [
									{
										id: "regular_service",
										title: "Regular Service",
										value: "regular_service",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_category",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Type and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_type",
								newOptions: [
									{
										id: "home_and_appliance_repair",
										title: "Home and Appliance Repair",
										value: "home_and_appliance_repair",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_type",
								isVisible: true,
							}),
						),
						/*

						* Setting Service Subtype and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_subtype",
								newOptions: [
									{
										id: "electrical_repair",
										title: "Electrical Repair",
										value: "electrical_repair",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_subtype",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_subtype",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;

			case "Pest Control":
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

						* Setting search Type to services and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "services",
										title: "Services",
										value: "services",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Category and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_category",
								newOptions: [
									{
										id: "regular_service",
										title: "Regular Service",
										value: "regular_service",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_category",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Type and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_type",
								newOptions: [
									{
										id: "cleaning_and_maintenance",
										title: "Cleaning and Maintenance",
										value: "cleaning_and_maintenance",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_type",
								isVisible: true,
							}),
						),
						/*

						* Setting Service Subtype and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_subtype",
								newOptions: [
									{
										id: "pest_control",
										title: "Pest Control",
										value: "pest_control",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_subtype",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_subtype",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;

			default:
				router.push("/search").then(() => {
					dispatch(
						updateSearchFilterSelectedOptionsThunk({
							searchFilterId: "location",
							newOptions: [
								{
									id: "location",
									title: "Location",
									value: {
										title: "HSR Layout",
										coordinates: [12.9121, 77.6446],
									},
								},
							],
						}),
					),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "location",
								isApplied: true,
							}),
						),
						/*

						* Setting search Type to services and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "search_type",
								newOptions: [
									{
										id: "services",
										title: "Services",
										value: "services",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "search_type",
								isApplied: true,
							}),
						),
						/*

						* Setting Service Category and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_category",
								newOptions: [
									{
										id: "regular_service",
										title: "Regular Service",
										value: "regular_service",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_category",
								isVisible: true,
							}),
						),
						/*

						* Setting Service Type and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_type",
								newOptions: [
									{
										id: "interiors_and_decor",
										title: "Interiors and Decor",
										value: "interiors_and_decor",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_type",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_type",
								isVisible: true,
							}),
						),
						/*

						* Setting Service Subtype and is applied to true

						*/
						dispatch(
							updateSearchFilterSelectedOptionsThunk({
								searchFilterId: "service_subtype",
								newOptions: [
									{
										id: "interior_design",
										title: "Interior Design",
										value: "interior_design",
									},
								],
							}),
						),
						dispatch(
							updateSearchFilterAppliedThunk({
								searchFilterId: "service_subtype",
								isApplied: true,
							}),
						),
						dispatch(
							updateSearchFilterVisibilityThunk({
								searchFilterId: "service_subtype",
								isVisible: true,
							}),
						),
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: true,
								TriggerSearch: true,
								ResetSearch: false,
								PropertySubCategory: searchanimationsettings?.PropertySubCategory,
								HomeAccordion: false,
							}),
						);
				});
				break;
		}
	};

	return (
		<MainContainer>
			<Heading variant="h6">Explore services</Heading>

			<CardsContainer>
				{service_categories.map((category, index) => (
					<CategoryCard
						key={index}
						onClick={() => {
							handleCardClick(category.title);
						}}
					>
						<SubText variant="subtitle1">{category.title}</SubText>

						<SvgIcon
							component={category.icon}
							inheritViewBox={false}
							viewBox="0 0 85 80"
							sx={{
								width: "3.5rem",
								height: "3.5rem",
							}}
						/>
					</CategoryCard>
				))}
			</CardsContainer>
		</MainContainer>
	);
};

export default ServicesSection;
