/*

? First, let's import the required components.

*/
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import React from "react";
import {
	ofb_property_category_options_active,
	property_category_options_active,
} from "../../../lib/data/search-filters/_d_property_category";
import StateInterface from "../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	setSearchFilterMetricesThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../redux-magic/thunks";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import LocationAutoComplete from "./SearchComponent/LocationAutoComplete";
/*

? Next let's import all the UI sub-components we intend to use on this page.

*/

/*

? Next, let's import the select change event signature.

*/

/*

& Let's define a custom attribute for our container called index. This will help the layout container organise our component correctly.

*/

type IndexAttribute = {
	index?: number;
};

/*

& Now let's style all the components we intend to use on this page.

*/

const SearchContainer = styled("div")<IndexAttribute>(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "flex-start",
	width: "100%",
	height: "100%",
	padding: "1rem",
	backgroundColor: theme.palette.background.paper,
	borderRadius: "16px",
	[theme.breakpoints.down("md1220")]: {
		padding: "0.65rem 1rem 0.65rem 1rem",
	},
	[theme.breakpoints.down("sm")]: {
		display: "none",
	},
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
	paddingLeft: "0.5rem",
	paddingRight: "0.5rem",
	[theme.breakpoints.down("md1220")]: {
		display: "none",
	},
}));

/*

& Next, let's create the component.

*/

const SearchAfterAnimationComponent = ({
	dispatch,
	search_filter_state,
	searchanimationsettings,
}: {
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const theme = useTheme();

	const [value, setValue] = React.useState(
		search_filter_state.search_type.selected_options[0].id === "properties"
			? search_filter_state.transaction_type.selected_options[0].id === "buy"
				? "Buy"
				: search_filter_state.transaction_type.selected_options[0].id === "rent"
					? "Rent"
					: "Open For Business"
			: search_filter_state.search_type.selected_options[0].id === "services"
				? "Services"
				: search_filter_state.search_type.selected_options[0].id === "business_profiles"
					? "Business Users"
					: null,
	);
	const handleChangeBusinessUsers = (value: string) => {
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_status", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));

		dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: true }));
		dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: true }));
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "transaction_type",
				newOptions: [],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "transaction_type",
				isApplied: false,
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "search_type",
				newOptions: [
					{
						id: value.toLocaleLowerCase(),
						title: value,
						value: value.toLocaleLowerCase(),
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: [
					{
						id: value.toLocaleLowerCase(),
						title: value,
						value: value.toLocaleLowerCase(),
					},
				],
			}),
		);

		const business_profile_type = search_filter_state.business_profile_type.active_options.map((item) => ({
			...item,
			is_selected: item.title === value ? true : false,
		}));

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "business_profile_type",
				newOptions: business_profile_type,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "search_type",
				isApplied: true,
			}),
		);

		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: "business_profile_type",
				isAccordionOpen: true,
			}),
		);
	};

	const handleChange = (event: SelectChangeEvent<string>) => {
		const newValue = event.target.value;
		if (value !== newValue) {
			let filtersapplied: string[] = Object.keys(search_filter_state).filter((key) => {
				return (
					search_filter_state[key as SearchFilterId].is_applied === true &&
					key !== "transaction_type" &&
					key !== "search_type" &&
					key !== "location"
				);
			});
			filtersapplied.map((filter) => {
				dispatch(updateSearchFilterAppliedThunk({ searchFilterId: filter as SearchFilterId, isApplied: false }));
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: filter as SearchFilterId,
						newOptions: [],
					}),
				);
			});
		}
		setValue(newValue);
		/*

		* Setting the search filter selected options based on the transaction type

		*/
		if (newValue === "Buy" || newValue === "Open For Business" || newValue === "Rent") {
			dispatch(
				updateSearchFilterActiveOptionsThunk({
					searchFilterId: "property_category",
					newOptions: property_category_options_active,
				}),
			);
			/*

			* updating service is applied and is visible

			*/
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: false }));
			/*

			* updating properties is applied and is visible

			*/
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));

			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "business_profile_type",
					newOptions: [],
				}),
			);

			/*

			 * need to update transaction type as well as search type same with isApplied true.

				! default value is done in index.tsx page for transaction_type

			*/
			if (newValue === "Buy") {
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: property_category_options_active,
					}),
				);
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "buy",
								title: "Buy",
								value: "buy",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
			}

			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: "properties",
							title: "Properties",
							value: "properties",
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);

			if (newValue === "Rent") {
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: property_category_options_active,
					}),
				);
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "rent",
								title: "Rent",
								value: "rent",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "security_deposit", isVisible: true }));
			}
			if (newValue === "Open For Business") {
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "transaction_type",
						newOptions: [
							{
								id: "ofb",
								title: "Open for business",
								value: "ofb",
							},
						],
					}),
				);
				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: "transaction_type",
						isApplied: true,
					}),
				);
				dispatch(
					updateSearchFilterActiveOptionsThunk({
						searchFilterId: "property_category",
						newOptions: ofb_property_category_options_active,
					}),
				);
			}
		} else if (newValue === "Services") {
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_category", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_type", isApplied: true }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "service_subtype", isApplied: true }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_category", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_subtype", isVisible: true }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_category", isApplied: false }));
			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "property_type", isApplied: false }));

			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_type", isVisible: false }));

			dispatch(updateSearchFilterAppliedThunk({ searchFilterId: "business_profile_type", isApplied: false }));
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "business_profile_type", isVisible: false }));
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "transaction_type",
					newOptions: [],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "transaction_type",
					isApplied: false,
				}),
			);
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "search_type",
					newOptions: [
						{
							id: newValue.toLocaleLowerCase(),
							title: newValue,
							value: newValue.toLocaleLowerCase(),
						},
					],
				}),
			);
			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: "search_type",
					isApplied: true,
				}),
			);
		} else if (newValue === "Business Users") {
			const value = "Agents";
			handleChangeBusinessUsers(value);
		}
	};

	return (
		<React.Fragment>
			<SearchContainer>
				<FormControl
					sx={{
						width: "15%",
						boxShadow: "none",
						".MuiOutlinedInput-notchedOutline": { border: 0 },
						"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
							border: 0,
						},
						"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
							border: 0,
						},
						[theme.breakpoints.down("md1220")]: {
							display: "none",
						},
					}}
				>
					<Select
						value={value}
						onChange={handleChange}
						inputProps={{ "aria-label": "Without label" }}
					>
						<MenuItem value={"Buy"}>Buy</MenuItem>
						<MenuItem value={"Rent"}>Rent</MenuItem>
						<MenuItem value={"Open For Business"}>Open For Business</MenuItem>
						<MenuItem value={"Services"}>Services</MenuItem>
						<MenuItem value={"Business Users"}>Business Users</MenuItem>
					</Select>
				</FormControl>
				<VerticalLine />
				<LocationAutoComplete
					search_id="location"
					dispatch={dispatch}
					search_filter_state={search_filter_state.location}
				/>
				<BeegruButton
					flavor="primary"
					variant="contained"
					size="large"
					onClick={() => {
						dispatch(
							setSearchFilterMetricesThunk({
								boosted: [0],
								non_boosted: [0],
								filter_hash: "",
								no_of_boosted_results: 0,
								no_of_non_boosted_results: 0,
								total_no_of_results: 0,
							}),
						);
						dispatch(
							setSearchAnimationDetailsThunk({
								StartAnimate: searchanimationsettings.StartAnimate,
								TriggerSearch: true,
								ResetSearch: true,
								PropertySubCategory: searchanimationsettings.PropertySubCategory,
								HomeAccordion: searchanimationsettings.HomeAccordion,
							}),
						);
					}}
				>
					Search
				</BeegruButton>
			</SearchContainer>
		</React.Fragment>
	);
};

export default SearchAfterAnimationComponent;
