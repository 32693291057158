/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */
import { Chip, useMediaQuery, useTheme } from "@mui/material";
import {
	agricultural_options_active,
	commercial_options_active,
	industrial_options_active,
	residential_options_active,
	warehousing_options_active,
} from "../../../../lib/data/search-filters/_e_property_type";
import StateInterface from "../../../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";

/* redux imports */

/* stylings */
const PropertyChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	alignItems: "center",
	gap: "1rem",
	paddingTop: "0.5rem",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

const PropertyCategoryMobile = ({
	search_id,
	search_filter_state,
	dispatch,
	TabValue,
	searchanimationsettings,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["property_category"];
	dispatch: Function;
	TabValue: string;
	searchanimationsettings: StateInterface["search_animation_settings"];
}) => {
	const theme = useTheme();

	const handleClickPropertyChips = (value: Array<SearchFilterOptionTextActive>) => {
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		const selectedOptionId = value[0]?.id;

		const updatedActiveOptions = search_filter_state.active_options.map((item) => ({
			...item,
			is_selected: item.id === selectedOptionId,
		}));

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: updatedActiveOptions,
			}),
		);

		const propertyTypeOptions =
			selectedOptionId === "residential"
				? residential_options_active
				: selectedOptionId === "commercial"
					? commercial_options_active
					: selectedOptionId === "agricultural"
						? agricultural_options_active
						: selectedOptionId === "warehousing"
							? warehousing_options_active
							: selectedOptionId === "industrial"
								? industrial_options_active
								: residential_options_active;
		if (selectedOptionId === "residential") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Bedroom", "Status", "Listed by", "Facing"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		}
		if (selectedOptionId === "commercial" || selectedOptionId === "agricultural") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		}
		if (selectedOptionId === "warehousing" || selectedOptionId === "industrial") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: searchanimationsettings.StartAnimate,
					TriggerSearch: searchanimationsettings.TriggerSearch,
					PropertySubCategory: ["Budget", "Status", "Listed by"],
					ResetSearch: searchanimationsettings.ResetSearch,
					HomeAccordion: searchanimationsettings.HomeAccordion,
				}),
			);
		}

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "property_type",
				newOptions: propertyTypeOptions,
			}),
		);

		if (
			search_filter_state.selected_options.length === 0 ||
			search_filter_state.selected_options[0]?.id !== selectedOptionId
		) {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_type",
					newOptions: [],
				}),
			);
		}
	};
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	React.useEffect(() => {
		if (isMobile) {
			if (TabValue === "Buy" || TabValue === "Rent" || TabValue === "Open for business") {
				const isApplied = search_filter_state.selected_options.length !== 0;
				const affectsId = search_filter_state.affects?.[0];

				if (affectsId) {
					dispatch(
						updateSearchFilterVisibilityThunk({
							searchFilterId: affectsId,
							isVisible: isApplied,
						}),
					);
				}

				dispatch(
					updateSearchFilterAppliedThunk({
						searchFilterId: search_id,
						isApplied: isApplied,
					}),
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [TabValue]);

	/*

* setting the initial values of selected item and scrolling to the selected item

*/
	let selectedItem = search_filter_state?.selected_options[0];
	const containerRef = React.useRef<HTMLDivElement>(null);
	/*

* trigger only when the selected item changes and in mobile

*/
	React.useEffect(() => {
		if (isMobile) {
			if (selectedItem && containerRef.current) {
				containerRef.current.scroll({ left: 0, behavior: "smooth" });
			}
		}
	}, [selectedItem, isMobile]);
	return (
		<Container ref={containerRef}>
			{search_filter_state.active_options?.map((item: SearchFilterOptionTextActive) => (
				<PropertyChips
					key={item?.id}
					onClick={() => {
						if (search_filter_state?.selected_options[0]?.id !== item.id) {
							handleClickPropertyChips([item] as Array<SearchFilterOptionTextActive>);
						}
					}}
					label={item?.title}
					clickable
					color="default"
					variant="outlined"
					sx={{
						fontSize: "1rem",
						backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
						color:
							theme.palette.mode === "dark"
								? search_filter_state?.selected_options[0]?.id === item.id
									? "#ffffff"
									: "rgba(255, 255, 255, 1)"
								: search_filter_state?.selected_options[0]?.id === item.id
									? "rgba(98, 56, 22, 1)"
									: "black",
						border:
							theme.palette.mode === "dark"
								? search_filter_state?.selected_options[0]?.id === item.id
									? "1px solid transparent"
									: "1px solid rgba(255, 255, 255, 0.23)"
								: search_filter_state?.selected_options[0]?.id === item.id
									? "1px solid transparent"
									: "1px solid rgba(0, 0, 0, 0.12)",
						"&.MuiChip-clickable:hover": {
							backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
						},
					}}
				/>
			))}
		</Container>
	);
};
export default PropertyCategoryMobile;
